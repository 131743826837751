export default {
  // extends: Line,
  data() {
    return {
      // start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,
      loading: false,
      //Profit
      profit_start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      profit_end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      profit_start_menu: false,
      profit_end_menu: false,
      countCustomerInvoice: 0,
      sumTotalIncomeBydate: 0,
      sumTotalProfitBydate: 0,
      sumTotalProfitByPercentBydate: 0,
      sumTotalBottle: 0,
      dashboard: {},

      //Sum
      sumCustomers: 0,
      sumCustomerActive: 0,
      sumCustomerUnActive: 0,
      sumCustomerActiveByPercent: 0,
      sumCustomerUnActiveByPercent: 0,
      sumTotalIncome: 0,
      sumTotalCost: 0,
      sumTotalProfit: 0,
      sumTotalProfitByPercent: 0,

      sumTotalCustomerInvoiceByCurrentYear: 0,
      sumTotalProfitByCurrentYear: 0,
      salePercent: 0,
      countCustomerByCurrentYear: 0,
      userPercent: 0,
      targetSaleOfFactory: 0,
      targetCustomerOfFactory: 0,

      sumTotalProfitByLastMonth: 0,
      sumTotalProfitByCurrentMonth: 0,
      sumTotalTargetGrowByCurrentMonth: 0,
      customerTypes: [],
      packages: [],
      InvoiceYearOfMonthly: [],
      packageName: [],

      //  Package Chart
      dataSet: {
        noData: {
          text: "Loading ...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color:     "#00c1d2",
            fontSize: "14px",
          },
        },
        title: {
          text: "User Selected Package",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "NotoSanLao, Google Sans, arial, serif, sans-serif !important",
            color: "#263238",
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            align: "center",
            style: {
              colors: [],
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          title: {
            text: "Package Name (Users) ",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
        },
        yaxis: {
          title: {
            text: "Incomes",
          },
        },

        labels: [],
        colors: ["#00c1d2", "#00c1d2"],
        dataLabels: {
          enabled: false,
        },
      },
      packageskey: [
        {
          data: [],
        },
      ],

      // Customer Category chart
      customercategory: {
        labels: [],
      },
      customerselected: [],

      //Sale monthly
      salevalue: [
        {
          data: [],
        },
      ],
      monthly: {
        noData: {
          text: "Loading ...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color:     "#00c1d2",
            fontSize: "14px",
          },
        },
        title: {
          text: "Monthly Sale",
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "NotoSanLao, Google Sans, arial, serif, sans-serif !important",
            color: "#263238",
          },
        },
        labels: [],
        colors: ["#00c1d2", "#00c1d2"],

        xaxis: {
          // categories: [1991, 1992, 1993],
          labels: {
            show: true,
            rotate: -45,
            align: "center",
            style: {
              colors: [],
              fontSize: "14px",
              fontWeight: 400,
            },
          },
          title: {
            text: "Monthly",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
        },
        yaxis: {
          title: {
            text: "Average (Bill)",
          },
        },
      },
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$axios
        .get("report/data/on/dashboard")
        .then((res) => {
          setTimeout(() => {
            this.sumCustomers = res.data.sumCustomers;
            this.sumCustomerActive = res.data.sumCustomerActive;
            this.sumCustomerUnActive = res.data.sumCustomerUnActive;
            this.sumCustomerActiveByPercent = res.data.sumCustomerActiveByPercent;
            this.sumCustomerUnActiveByPercent = res.data.sumCustomerUnActiveByPercent;
            this.sumTotalIncome = res.data.sumTotalIncome;
            this.sumTotalCost = res.data.sumTotalCost;
            this.sumTotalProfit = res.data.sumTotalProfit;
            this.sumTotalProfitByPercent = res.data.sumTotalProfitByPercent;
            this.customerTypes = res.data.customerTypes;

            this.customerTypes.map((item) => {
              this.customerselected.push(item.userSelectedType);
              this.customercategory.labels.push(
                item.name + " (" + item.userSelectedType + ")"
              );
            });

            this.sumTotalCustomerInvoiceByCurrentYear =
              res.data.sumTotalCustomerInvoiceByCurrentYear;
            this.sumTotalProfitByCurrentYear = res.data.sumTotalProfitByCurrentYear;
            this.salePercent = res.data.sumTotalTargetSaleByPercent;
            this.countCustomerByCurrentYear = res.data.countCustomerByCurrentYear;
            this.userPercent = res.data.sumTotalTargetCustomerByPercent;
            this.targetSaleOfFactory = res.data.targetSaleOfFactory;
            this.targetCustomerOfFactory = res.data.targetCustomerOfFactory;
            this.packages = res.data.packages;

            this.packages.map((item) => {
              // packageSet.data.push(item.sumTotalIncome);
              // packageSet.labels.push(item.name + " (" + item.userSelectedPackage + ")");
              this.packageskey[0].data.push(item.sumTotalIncome);
              this.dataSet.labels.push(item.name + " (" + item.userSelectedPackage + ")");
              window.dispatchEvent(new Event("resize"));
            });
            this.InvoiceYearOfMonthly =
              res.data.sumTotalCustomerInvoiceByCurrentYearOfEveryMonth;
            this.InvoiceYearOfMonthly.map((item) => {
              // monthlySet.data.push(item.countCustomerInvoice);
              // monthlySet.labels.push(item.id);
              this.salevalue[0].data.push(item.countCustomerInvoice);
              this.monthly.labels.push(item.id);
              window.dispatchEvent(new Event("resize"));
            });

            this.sumTotalTargetGrowByCurrentMonth =
              res.data.sumTotalTargetGrowByCurrentMonth;
            this.sumTotalProfitByCurrentMonth = res.data.sumTotalProfitByCurrentMonth;
            this.loading = false;
          }, 1000);
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    async fetchProfit() {
      this.loading = true;
      await this.$axios
        .post("report/profit/on/dashboard", {
          startDate: this.profit_start_date,
          endDate: this.profit_end_date,
        })
        .then((res) => {
            this.countCustomerInvoice = res.data.countCustomerInvoice;
            this.sumTotalIncomeBydate = res.data.sumTotalIncome;
            this.sumTotalProfitBydate = res.data.sumTotalProfit;
            this.sumTotalProfitByPercentBydate = res.data.sumTotalProfitByPercent;
            this.sumTotalBottle = res.data.sumTotalBottle;
            this.loading = false;
            this.profit_start_menu = false;
            this.profit_end_menu = false;
        })
        .catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
          this.profit_start_menu = false;
          this.profit_end_menu = false;
          this.loading = false;
        });
    },
  },
  created() {
    // this.fetchData();
    // this.fetchProfit();
  },
};
